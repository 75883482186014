import logo from '../assets/logo.svg';

export default async () =>
	await new Promise(async (resolve) => {
		const config = {
			whiteLabel: 'Ontwikkelcentrum',
			files: [
				{
					id: 'testfile',
					file: logo,
				},
			],
			general: {
				showMenuLabel: true,
				showTags: false,
				showFilters: true,
				secondStatisticsBox: true,
				downloadRapportage: true,
				hideTeacherMessage: true,
				hideReadSpeaker: true,
				hideLogout: true,
				sso: true,
				disableLoginPage: true,
			},
			pages: {
				general: {
					showStudentRapports: true,
				},
				appLogin: {
					showHeaderTitle: false,
					showLogo: true,
					headerTitleIsHtml: false,
					showFootNote: false,
					visualTitle: false,
					footerLogo: true,
				},
				appRegistration: {
					showBirthDate: true,
					showPhoneNumber: true,
					showRegisterType: false,
					showRegisterCode: false,
					registerCodeRequired: false,
					addMoreLicenses: true,
					hideGender: true,
					hideRegistration: true,
				},
				appNoLicense: {
					showFootNote: false,
				},
				appAccount: {
					showEducation: false,
					hideFunction: true,
					showMenu: true,
				},
				appElementOverlay: {
					showHeaderTitle: true,
				},
				dashboard: {
					scrollTo: false,
				},
				appDashboard: {
					alternativeContent: false,
				},
				boxCard: {
					showArrow: false,
					showDescription: true,
					showIconLabel: true,
					animateLabel: false,
					showThemeIconsInList: true,
				},
				appBox: {
					showResultsPercentage: true,
				},
			},
			elements: {
				exerciseCard: true,
			},
			caseConfig: {
				redoButtons: true,
			},
			getFile: (id) => config.files.find((file) => file.id === id),
			getPage: (key) => config[key],
		};

		resolve(config);
	});
